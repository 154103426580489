.contact {
  background-color: $red;
  color: $white;
  overflow: hidden;
  padding: 80px 0;
  position: relative;
  @include media($w1024) {
    padding: 70px 0;
  }
  &:before {
    @include translateX;
    content: '';
    background-image: url(../images/home--about-us--bg.svg);
    background-size: cover;
    background-position: right center;
    width: 1511px;
    height: 935px;
    position: absolute;
    top: -30px;
    left: calc(50% - 250px);
  }
  .container {
    z-index: 50;
  }
  &__inner {
    align-items: center;
    display: flex;
    @include media($w1024) {
      flex-wrap: wrap;
    }
  }
  &__form {
    background-color: rgba($white,0.2);
    flex: 0 0 49%;
    clip-path: polygon(30px 0,100% 0,100% 100%,0 100%,0 30px);
    padding: 70px 40px 85px;
    @include media($w1366) {
      padding: 60px 40px;
    }
    @include media($w1024) {
      flex: 0 0 100%;
      margin: 0 0 60px;
      padding: 50px 40px 60px;
    }
    @include media($w768) {
      margin: 0 0 50px;
      padding: 45px 30px 55px;
    }
    h2 {
      text-align: center;
      letter-spacing: -0.02em;
      margin: 0 0 25px;
    }
    p {
      text-align: center;
      margin: 0 0 35px;
    }
  }
  &__data {
    flex: 0 0 51%;
    padding: 0 0 0 150px;
    @include media($w1366) {
      padding: 0 0 0 50px;
    }
    @include media($w1024) {
      flex: 0 0 100%;
      padding: 0;
    }
    h3 {
      font-size: 36px;
      line-height: 36px;
      text-align: center;
      margin: 0 0 70px;
      display: inline-block;
      @include media($w1366) {
        font-size: 33px;
        line-height: 41px;
        margin: 0 0 60px;
      }
      @include media($w1024) {
        font-size: 30px;
        line-height: 36px;
        margin: 0 auto 50px;
        display: block;
      }
      @include media($w768) {
        font-size: 27px;
        line-height: 33px;
        margin: 0 auto 40px;
      }
      @include media($w560) {
        font-size: 24px;
        line-height: 30px;
      }
    }
    &-item {
      align-items: center;
      display: flex;
      & + .contact {
        &__data {
          &-item {
            margin: 30px 0 0;
          }
        }
      }
      &-icon {
        margin: 0 25px 0 0;
        @include media($w768) {
          margin: 0 20px 0 0;
        }
        img {
          width: 53px;
          @include media($w768) {
            width: 45px;
          }
          @include media($w560) {
            width: 40px;
          }
        }
      }
      &-text {
        font-family: 'Calibri' !important;
        font-size: 20px;
        line-height: 24px;
        @include media($w768) {
          font-size: 18px;
          line-height: 22px;
        }
        a {
          font-family: 'Calibri' !important;
          font-size: 36px;
          line-height: 30px;
          color: $white;
          @include media($w1366) {
            font-size: 33px;
            line-height: 41px;
          }
          @include media($w1024) {
            font-size: 30px;
            line-height: 36px;
          }
          @include media($w768) {
            font-size: 26px;
            line-height: 32px;
          }
          @include media($w560) {
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
