.home-page {
  .news {
    margin: 120px 0;
    @include media($w1366) {
      margin: 100px 0;
    }
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    &__mobile-header {
      margin: 0 0 80px;
      display: none;
      @include media($w1024) {
        display: block;
      }
      @include media($w768) {
        margin: 0 0 55px;
      }
    }
    &__inner {
      display: flex;
      @include media($w1024) {
        flex-wrap: wrap;
      }
    }
    &__header {
      margin: 0 0 40px;
      @include media($w1024) {
        display: none;
      }
    }
    &__left {
      flex: 0 0 37%;
      @include media($w1366) {
        flex: 0 0 38%;
      }
      @include media($w1200) {
        flex: 0 0 40%;
      }
      @include media($w1024) {
        flex: 0 0 100%;
        order: 2;
      }
      .page-label {
        @include media($w1024) {
          display: none;
        }
      }
    }
    &__item {
      padding: 0 40px 0 0;
      align-items: center;
      display: flex;
      @include media($w1366) {
        padding: 0 30px 0 0;
      }
      @include media($w1024) {
        padding: 0;
      }
      @include media($w560) {
        align-items: flex-start;
      }
      & + .news {
        &__item {
          margin: 45px 0 0;
          @include media($w1366) {
            margin: 35px 0 0;
          }
          @include media($w1024) {
            margin: 25px 0 0;
          }
        }
      }
      &-image {
        background-size: cover;
        background-position: center center;
        flex: 0 0 154px;
        height: 154px;
        margin: 0 20px 0 0;
        position: relative;
        @include media($w1366) {
          flex: 0 0 140px;
          height: 140px;
        }
        @include media($w768) {
          flex: 0 0 120px;
          height: 120px;
        }
        &-click {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      &-text {
        flex: 1 1 auto;
        h5 {
          margin: 0 0 12px;
          @include media($w1200) {
            margin: 0 0 8px;
          }
          a {
            color: $gray;
            &:hover {
              color: $red;
            }
          }
        }
        p {
          font-size: 14px;
          line-height: 24px;
          margin: 0 0 12px;
          @include media($w1200) {
            line-height: 22px;
            margin: 0 0 8px;
          }
        }
        & > a {
          font-family: 'Montserrat', sans-serif !important;
        	font-size: 12px;
        	font-weight: 300;
        	line-height: 18px;
          padding: 0 0 0 15px;
          display: inline-block;
          position: relative;
          &:before {
            @include translateY;
            content: '';
            background-image: url(../images/icons/angle--next--red.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center left;
            width: 10px;
            height: 10px;
            position: absolute;
            top: calc(50% + 1px);
            left: 0;
          }
        }
      }
    }
    &__right {
      flex: 0 0 63%;
      @include media($w1366) {
        flex: 0 0 62%;
      }
      @include media($w1200) {
        flex: 0 0 60%;
      }
      @include media($w1024) {
        flex: 0 0 100%;
        order: 1;
        margin: 0 0 30px;
      }
    }
    &__main {
      &-image {
        margin: 0 0 24px;
        img {
          width: 100%;
        }
      }
      time {
        font-family: 'Montserrat', sans-serif !important;
      	font-size: 12px;
      	font-weight: 300;
      	line-height: 18px;
        opacity: 0.65;
        margin: 0 0 12px;
        display: block;
      }
      h4 {
        margin: 0 0 16px;
        a {
          color: $gray;
          &:hover {
            color: $red;
          }
        }
      }
      p {
        margin: 0 0 16px;
      }
      & > a {
        font-family: 'Montserrat', sans-serif !important;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        padding: 0 0 0 15px;
        display: inline-block;
        position: relative;
        &:before {
          @include translateY;
          content: '';
          background-image: url(../images/icons/angle--next--red.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center left;
          width: 10px;
          height: 10px;
          position: absolute;
          top: calc(50% + 1px);
          left: 0;
        }
      }
    }
  }
}
