.text-section {
  margin: 120px 0;
  @include media($w1366) {
    margin: 100px 0;
  }
  @include media($w1024) {
    margin: 80px 0;
  }
  @include media($w768) {
    margin: 60px 0;
  }
  &__header {
    margin: 0 0 45px;
  }
  &__body {
    & + .text-section {
      &__body {
        margin: 90px 0 0;
        @include media($w1366) {
          margin: 80px 0 0;
        }
        @include media($w1024) {
          margin: 70px 0 0;
        }
        @include media($w768) {
          margin: 60px 0 0;
        }
      }
    }
    h4 {
      margin: 0 0 30px;
    }
    &-inner {
      justify-content: space-between;
      display: flex;
      @include media($w1024) {
        flex-wrap: wrap;
      }
      &-col {
        flex: 0 0 calc(50% - 30px);
        @include media($w1366) {
          flex: 0 0 calc(50% - 25px);
        }
        @include media($w1024) {
          flex: 0 0 100%;
        }
        & + .text-section {
          &__body {
            &-inner {
              &-col {
                @include media($w1024) {
                  margin: 30px 0 0;
                }
                @include media($w560) {
                  margin: 20px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
