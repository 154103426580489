.about-us-page {
  .call-us {
    height: auto;
    color: $white;
    padding: 160px 0;
    @include media($w1366) {
      padding: 135px 0;
    }
    @include media($w1024) {
      padding: 110px 0;
    }
    @include media($w768) {
      padding: 85px 0;
    }
    h2 {
      text-align: center;
    }
  }
}
