.home-page {
  .business-partner {
    margin: 120px 0;
    @include media($w1366) {
      margin: 100px 0;
    }
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    &__header {
      height: auto;
      text-align: center;
      color: $white;
      padding: 125px 75px 200px;
      position: relative;
      z-index: 25;
      @include media($w1366) {
        padding: 105px 50px 150px;
      }
      @include media($w1024) {
        padding: 85px 30px 115px;
      }
      @include media($w768) {
        padding: 75px 30px 105px;
      }
      @include media($w560) {
        padding: 60px 30px 95px;
      }
      &::before {
        content: '';
        background-color: rgba($black, 0.5);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      h2 {
        br {
          @include media($w560) {
            display: none;
          }
        }
        a {
          color: $white;
        }
      }
      &-title {
        position: relative;
        z-index: 25;
      }
      .page-label {
        position: relative;
        z-index: 25;
      }
    }
    &__body {
      color: $white;
      margin: -75px 0 0;
      display: flex;
      position: relative;
      z-index: 50;
      @include media($w1366) {
        margin: -50px 0 0;
      }
      @include media($w1024) {
        margin: -40px 0 0;
        flex-wrap: wrap;
      }
      &-copy {
        background-color: $red;
        flex: 0 0 58%;
        min-height: 760px;
        padding: 80px 80px 160px;
        clip-path: polygon(75px 0,100% 0,100% 100%,0 100%,0 75px);
        position: relative;
        @include media($w1366) {
          flex: 0 0 55%;
          min-height: 650px;
          padding: 60px 50px 130px;
          clip-path: polygon(50px 0,100% 0,100% 100%,0 100%,0 50px);
        }
        @include media($w1024) {
          flex: 0 0 100%;
          min-height: 1px;
          padding: 50px 30px 120px;
          clip-path: polygon(40px 0,100% 0,100% 100%,0 100%,0 40px);
        }
        @include media($w560) {
          padding: 45px 15px 165px;
          clip-path: polygon(40px 0,100% 0,100% 100%,0 100%,0 40px);
        }
        &:before {
          @include translateX;
          content: '';
          background-image: url(../images/home--about-us--bg.svg);
          background-size: cover;
          background-position: right center;
          width: 1402px;
          height: 866px;
          pointer-events: none;
          position: absolute;
          z-index: 25;
          top: -30px;
          left: calc(50% - 100px);
        }
        &-text {
          position: relative;
          z-index: 50;
          h2 {
            margin: 0 0 50px;
            @include media($w1024) {
              margin: 0 0 40px;
            }
            @include media($w560) {
              margin: 0 0 35px;
            }
          }
        }
        &-benefits {
          width: 100%;
          display: flex;
          position: absolute;
          z-index: 50;
          bottom: 0;
          left: 0;
          @include media($w768) {
            flex-wrap: wrap;
          }
          &-item {
            background-color: $gray;
            flex: 0 0 50%;
            height: 80px;
            font-family: 'Calibri' !important;
            font-size: 28px;
            font-weight: 700;
            line-height: 28px;
            text-transform: uppercase;
            justify-content: center;
            align-items: center;
            display: flex;
            @include media($w1366) {
              height: 70px;
              font-size: 24px;
              line-height: 24px;
              padding: 0 0 4px;
            }
            @include media($w1024) {
              height: 65px;
              font-size: 22px;
              line-height: 22px;
              padding: 0 0 2px;
            }
            @include media($w768) {
              flex: 0 0 100%;
              height: 60px;
            }
            &:nth-child(2n+2) {
              background-color: $gray--dark;
              font-size: 24px;
              line-height: 24px;
              @include media($w1024) {
                font-size: 22px;
                line-height: 22px;
              }
            }
          }
        }
      }
      &-image {
        background-size: cover;
        background-position: center center;
        flex: 0 0 42%;
        @include media($w1366) {
          flex: 0 0 45%;
        }
        @include media($w1024) {
          flex: 0 0 100%;
          height: 550px;
        }
        @include media($w768) {
          height: 500px;
        }
        @include media($w560) {
          height: 450px;
        }
      }
    }
  }
}
