.home-page {
  .about-us {
    margin: -75px 0 0;
    position: relative;
    z-index: 50;
    @include media($w1366) {
      margin: -50px 0 0;
    }
    @include media($w1024) {
      margin: -40px 0 0;
    }
    @include media($w768) {
      margin: 60px 0 0;
    }
    &__inner {
      background-color: $red;
      color: $white;
      overflow: hidden;
      padding: 70px 110px;
      clip-path: polygon(75px 0,100% 0,100% 100%,0 100%,0 75px);
      position: relative;
      @include media($w1366) {
        padding: 60px 50px;
        clip-path: polygon(50px 0,100% 0,100% 100%,0 100%,0 50px);
      }
      @include media($w1024) {
        padding: 50px 30px;
        clip-path: polygon(40px 0,100% 0,100% 100%,0 100%,0 40px);
      }
      &:before {
        content: '';
        background-image: url(../images/home--about-us--bg.svg);
        background-size: cover;
        background-position: right center;
        width: 1402px;
        height: 866px;
        position: absolute;
        top: -30px;
        left: -400px;
        @include media($w1024) {
          width: 1000px;
          height: 618px;
          left: -30%;
        }
        @include media($w560) {
          width: 800px;
          height: 494px;
          left: -45%;
        }
      }
    }
    &__copy {
      margin: 0 0 60px;
      position: relative;
      z-index: 25;
      @include media($w1366) {
        margin: 0 0 50px;
      }
      @include media($w1024) {
        margin: 0 0 35px;
      }
      h2 {
        margin: 0 0 45px;
        @include media($w1024) {
          margin: 0 0 35px;
        }
      }
      p {
        font-family: 'Montserrat', sans-serif !important;
        font-size: 24px;
        line-height: 36px;
        @include media($w1366) {
      		font-size: 22px;
      		line-height: 32px;
      	}
      	@include media($w1024) {
      		font-size: 20px;
      		line-height: 28px;
      	}
      }
    }
    &__columns {
      margin: 0 0 -40px;
      display: flex;
      position: relative;
      z-index: 25;
      @include media($w1024) {
        margin: 0 0 -30px;
      }
      @include media($w768) {
        flex-wrap: wrap;
      }
      &-item {
        flex: 0 0 calc(100% / 3 - 40px);
        margin: 0 60px 40px 0;
        @include media($w1366) {
          flex: 0 0 calc(100% / 3 - 30px);
          margin: 0 45px 40px 0;
        }
        @include media($w1024) {
          flex: 0 0 calc(100% / 3 - 20px);
          margin: 0 30px 30px 0;
        }
        @include media($w768) {
          flex: 0 0 100%;
          margin: 0 0 30px;
        }
        &:nth-child(3n+3) {
          margin: 0 0 40px;
          @include media($w1024) {
            margin: 0 0 30px;
          }
        }
        h4 {
          margin: 0 0 30px;
          @include media($w768) {
            margin: 0 0 20px;
          }
        }
        p {
          font-size: 14px;
          line-height: 22px;
          @include media($w1024) {
        		line-height: 20px;
        	}
        }
      }
    }
  }
}
