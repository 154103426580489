.post-page {
  .post {
    &__main {
      flex: 1 1 auto;
      @include media($w1024) {
        flex: 0 0 100%;
      }
      &-info {
        opacity: 0.85;
        margin: 0 0 45px;
        display: flex;
        @include media($w1024) {
          margin: 0 0 40px;
        }
        @include media($w768) {
          margin: 0 0 35px;
        }
        time {
          font-family: 'Montserrat', sans-serif !important;
        	font-size: 12px;
        	font-weight: 300;
        	line-height: 24px;
          margin: 0 5px 0 0;
          &:after {
            content: ', ';
          }
        }
        ul {
          display: flex;
          li {
            font-family: 'Montserrat', sans-serif !important;
          	font-size: 12px;
          	font-weight: 300;
          	line-height: 24px;
            text-transform: uppercase;
            margin: 0 5px 0 0;
            &:after {
              content: ', ';
            }
            &:last-child {
              margin: 0;
            }
            a {
              color: $gray;
            }
            &:last-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
