@keyframes textAnimation01 {
  from {
    left: calc(100% + 150px);
  }
  to {
    left: 0%;
  }
}
@keyframes textAnimation02 {
  from {
    left: calc(100% + 300px);
  }
  to {
    left: 0%;
  }
}
@keyframes textAnimation03 {
  from {
    left: calc(100% + 450px);
  }
  to {
    left: 0%;
  }
}
@keyframes textAnimation04 {
  from {
    left: calc(100% + 600px);
  }
  to {
    left: 0%;
  }
}
@keyframes textAnimation05 {
  from {
    left: calc(100% + 750px);
  }
  to {
    left: 0%;
  }
}

.home-page {
  .hero {
    position: relative;
    z-index: 25;
    &__slide {
      background-size: cover;
      background-position: center center;
      height: 787px;
      display: flex;
      position: relative;
      @include media($w1366) {
        height: 700px;
      }
      @include media($w1024) {
        height: 600px;
      }
      @include media($w768) {
        height: 500px;
      }
      @include media($w560) {
        height: 425px;
      }
      &::before {
        content: '';
        background-color: rgba($black, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      &-inner {
        align-items: center;
        display: flex;
        &-text {
          overflow: hidden;
          padding: 0 0 80px;
          display: inline-block;
          @include media($w1366) {
            padding: 0 30px 60px;
          }
          @include media($w1024) {
            padding: 0 45px 40px;
          }
          @include media($w768) {
            padding: 0;
          }
          h1 {
            color: $white;
            text-shadow: 0px 3px 6px rgba($black,0.16);
            span {
              display: block;
              transition: left 0.4s ease-out 0s, padding 0.4s ease-out 0s, text-indent 0.4s ease-out 0s;
              position: relative;
              left: 0%;
            }
          }
          a {
            color: $white;
          }
        }
      }
      &.swiper-slide-active {
        .hero {
          &__slide {
            &-inner {
              &-text {
                h1 {
                  span {
                    animation-duration: 1.5s;
                    animation-timing-function: ease-in-out;
                    &:nth-child(1) {
                      animation-name: textAnimation01;
                    }
                    &:nth-child(2) {
                      animation-name: textAnimation02;
                    }
                    &:nth-child(3) {
                      animation-name: textAnimation03;
                    }
                    &:nth-child(4) {
                      animation-name: textAnimation04;
                    }
                    &:nth-child(5) {
                      animation-name: textAnimation05;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &__navigation {
      @include transition;
      @include translateY;
      width: calc(100% - 60px);
      opacity: 0;
      pointer-events: none;
      justify-content: space-between;
      display: flex;
      position: absolute;
      z-index: 100;
      top: calc(50% - 35px);
      left: 30px;
      @include media($w1366) {
        top: calc(50% - 20px);
      }
      @include media($w1024) {
        top: calc(50% - 15px);
      }
      @include media($w768) {
        display: none;
      }
      &-prev,
      &-next {
        cursor: pointer;
        pointer-events: auto;
        img {
          width: 20px;
        }
      }
    }
    &__dots {
      width: 100%;
      justify-content: center;
      display: flex;
      position: absolute;
      z-index: 100;
      bottom: 120px;
      left: 0;
      @include media($w1366) {
        bottom: 100px;
      }
      @include media($w1024) {
        bottom: 75px;
      }
      @include media($w768) {
        bottom: 35px;
      }
      .swiper-pagination-bullet {
        @include transition;
        border: 2px solid rgba($white,0.5);
        background-color: rgba($white,0.3);
        width: 25px;
        height: 25px;
        opacity: 1;
        margin: 0 7px;
        position: relative;
        &:before {
          @include transition;
          border-radius: 50%;
          content: '';
          background-color: $white;
          width: 9px;
          height: 9px;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 6px;
          left: 6px;
        }
        &.swiper-pagination-bullet-active {
          border-color: $white;
          &:before {
            opacity: 1;
          }
        }
      }
    }
    &:hover {
      .hero {
        &__navigation {
          opacity: 1;
        }
      }
    }
  }
}
