.btn {
	@include transition;
	background-color: $red;
	min-width: 178px;
	height: 57px;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: $white;
	padding: 0 20px;
	justify-content: center;
	align-items: center;
	display: inline-flex;
	@include media($w1024) {
		background-color: $gray--light;
		color: $gray;
	}
	&:hover {
		background-color: $gray--light;
		color: $gray;
	}
	&--gray {
		background-color: $gray--light;
		color: $gray;
		&:hover {
			background-color: $gray--dark;
			color: $white;
		}
	}
}
