.about-us-page {
  .image {
    margin: 120px 0;
    @include media($w1366) {
      margin: 100px 0;
    }
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    img {
      width: 100%;
      @include media($w768) {
        width: 150%;
        margin: 0 -25%;
      }
    }
  }
}
