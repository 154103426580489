.parallax {
  background: transparent;
  height: 500px;
  position: relative;
  @include media($w1366) {
    height: 450px;
  }
  @include media($w1024) {
    height: 400px;
  }
  @include media($w768) {
    height: 350px;
  }
  @include media($w560) {
    height: 300px;
  }
  &::before {
    content: '';
    background-color: rgba($black, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
