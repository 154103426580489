.blog-page {
  .posts-list {
    margin: 0 0 100px;
    @include media($w1024) {
      margin: 0 0 80px;
    }
    @include media($w768) {
      margin: 0 0 60px;
    }
    &__item {
      margin: 100px 0 0;
      display: flex;
      @include media($w1024) {
        margin: 80px 0 0;
      }
      @include media($w768) {
        margin: 60px 0 0;
        flex-wrap: wrap;
      }
      &-image {
        background-size: cover;
        background-position: center center;
        flex: 0 0 50%;
        position: relative;
        @include media($w768) {
          flex: 0 0 100%;
          height: 300px;
        }
        @include media($w560) {
          height: 250px;
        }
        &-click {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      &-copy {
        flex: 1 1 auto;
        padding: 40px 0 40px 60px;
        @include media($w1366) {
          padding: 35px 0 35px 50px;
        }
        @include media($w1024) {
          padding: 30px 0 30px 30px;
        }
        @include media($w768) {
          flex: 0 0 100%;
          padding: 30px 0 0;
        }
        time {
          font-family: 'Montserrat', sans-serif !important;
          font-size: 12px;
          font-weight: 300;
          line-height: 16px;
          opacity: 0.75;
          margin: 0 0 20px;
          display: block;
        }
        h4 {
          margin: 0 0 22px;
          a {
            color: $gray;
            &:hover {
              color: $red;
            }
          }
        }
        p {
          margin: 0 0 45px;
          @include media($w1366) {
            margin: 0 0 40px;
          }
          @include media($w1024) {
            margin: 0 0 35px;
          }
          @include media($w768) {
            margin: 0 0 25px;
          }
        }
        & > a {
          font-family: 'Montserrat', sans-serif !important;
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          color: $red;
          padding: 0 0 0 15px;
          display: inline-block;
          position: relative;
          &:before {
            @include translateY;
            content: '';
            background-image: url(../images/icons/angle--next--red.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center left;
            width: 10px;
            height: 10px;
            position: absolute;
            top: calc(50% + 1px);
            left: 0;
          }
          &:hover {
            color: $gray;
          }
        }
      }
    }
  }
}
