.header {
  background-color: $white;
  position: relative;
  z-index: 100;
  &__inner {
    justify-content: space-between;
    align-items: center;
    display: flex;
    @include media($w1024) {
      height: 80px;
    }
    @include media($w768) {
      height: 70px;
    }
  }
  &__logo {
    a {
      img {
        width: 215px;
        @include media($w1366) {
      		width: 180px;
      	}
        @include media($w768) {
      		width: 166px;
      	}
      }
    }
  }
  &__wrapper {
    align-items: center;
    display: flex;
    @include media($w1024) {
      @include transition;
      background-color: $red;
      width: 100%;
      height: 100vh;
      opacity: 0;
      pointer-events: none;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
  		position: fixed;
      top: -100vh;
      left: 0;
  	}
    &.visible {
      @include media($w1024) {
        opacity: 1;
        pointer-events: auto;
        top: 0;
      }
    }
  }
  &__menu {
    margin: 0 70px 0 0;
    @include media($w1366) {
      margin: 0 30px 0 0;
    }
    @include media($w1024) {
      flex: 0 0 100%;
      margin: 0 0 25px;
    }
    & > ul {
      margin: 0 -20px;
      display: flex;
      @include media($w1366) {
        margin: 0 -15px;
      }
      @include media($w1024) {
        margin: 0;
        display: block;
    	}
      & > li {
        font-family: 'Calibri' !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        display: flex;
        position: relative;
        @include media($w1024) {
          flex-wrap: wrap;
          justify-content: center;
        }
        & + li {
          @include media($w1024) {
            margin: 6px 0 0;
          }
        }
        & > a {
          height: 98px;
          color: $gray;
          padding: 0 20px;
          align-items: center;
          display: inline-flex;
          @include media($w1366) {
            height: 90px;
            padding: 0 15px;
          }
          @include media($w1024) {
            height: auto;
            color: $white;
          }
        }
        & > ul.sub-menu {
          @include transition;
          background-color: darken($white,2%);
          width: 250px;
          opacity: 0;
          pointer-events: none;
          padding: 20px 30px 22px;
          position: absolute;
          top: calc(100% - 20px);
          left: -10px;
          @include media($w1024) {
            background-color: transparent;
            width: auto;
            flex: 0 0 100%;
            text-align: center;
            opacity: 1;
            pointer-events: auto;
            margin: 4px 0 0;
            padding: 0;
            position: relative;
            top: auto;
            left: auto;
          }
          & > li {
            text-transform: none;
            & + li {
              margin: 4px 0 0;
              @include media($w1024) {
                margin: 2px 0 0;
              }
            }
            a {
              color: $gray;
              @include media($w1024) {
                color: $white;
              }
              &:hover {
                color: $red;
                @include media($w1024) {
                  color: $white;
                }
              }
            }
            &.current-menu-item {
              & > a {
                color: $red;
                @include media($w1024) {
                  text-decoration: underline;
                  color: $white;
                }
              }
            }
          }
        }
        &.current-menu-item {
          & > a {
            color: $red;
            @include media($w1024) {
              text-decoration: underline;
              color: $white;
            }
          }
        }
        &:hover {
          & > a {
            color: $red;
            @include media($w1024) {
              color: $white;
            }
          }
          & > ul.sub-menu {
            opacity: 1;
            pointer-events: auto;
            top: 100%;
          }
        }
      }
    }
  }
  &__trigger {
    width: 26px;
    height: 21px;
    cursor: pointer;
    align-items: center;
    display: none;
    position: absolute;
    top: 29px;
    right: 30px;
    @include media($w1024) {
      display: flex;
    }
    @include media($w768) {
      top: 25px;
    }
    span {
      @include transition;
      border-radius: 2px;
      background-color: $gray--dark;
      flex: 0 0 100%;
      height: 4px;
      position: relative;
      &:before,
      &:after {
        @include transition;
        content: '';
        border-radius: 2px;
        background-color: $gray--dark;
        height: 4px;
        width: 100%;
        position: absolute;
        left: 0;
      }
      &:before {
        top: -8px;
      }
      &:after {
        bottom: -8px;
      }
    }
    &.visible {
      position: fixed;
      span {
        background-color: transparent;
        &:before,
        &:after {
          background-color: $white;
        }
        &:before {
          transform: rotate(45deg);
          top: 0;
        }
        &:after {
          transform: rotate(-45deg);
          bottom: 0;
        }
      }
    }
  }
}
