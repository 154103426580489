@import "pages/post/body/main";
@import "pages/post/body/sidebar";

.post-page {
  .post {
    margin: 100px 0;
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    &__inner {
      display: flex;
      @include media($w1024) {
        flex-wrap: wrap;
      }
    }
  }
}
