.references {
  &__list {
    margin: 0 0 -75px;
    padding: 0 0 120px;
    flex-wrap: wrap;
    display: flex;
    @include media($w1366) {
      padding: 0 0 100px;
    }
    @include media($w1024) {
      margin: 0 0 -50px;
      padding: 0 0 80px;
    }
    @include media($w768) {
      padding: 0 0 60px;
    }
    @include media($w560) {
      margin: 0 0 -30px;
    }
    &-item {
      flex: 0 0 calc(100% / 3 - 19px);
      margin: 0 28px 75px 0;
      @include media($w1024) {
        flex: 0 0 calc(100% / 2 - 10px);
        margin: 0 20px 50px 0;
      }
      @include media($w560) {
        flex: 0 0 100%;
        margin: 0 0 30px;
      }
      &:nth-child(3n+3) {
        margin: 0 0 75px;
        @include media($w1024) {
          margin: 0 20px 50px 0;
        }
        @include media($w560) {
          margin: 0 0 30px;
        }
      }
      &:nth-child(2n+2) {
        @include media($w1024) {
          margin: 0 0 50px;
        }
        @include media($w560) {
          margin: 0 0 30px;
        }
      }
      a {
        color: $gray;
      }
      &-logo {
        @include transition;
        background-color: $white;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        height: 253px;
        @include media($w1366) {
          height: 250px;
        }
        @include media($w1024) {
          height: 235px;
        }
        @include media($w768) {
          height: 220px;
        }
        @include media($w560) {
          height: 200px;
        }
      }
      h4 {
        padding: 30px 0 18px;
      }
      &:hover {
        .references {
          &__list {
            &-item {
              &-logo {
                box-shadow: 0px 20px 25px -5px rgba($black,0.15);
              }
            }
          }
        }
      }
    }
  }
}
