* {
	@include box-sizing;
	&:before,
	&:after {
		@include box-sizing;
	}
}

::-moz-selection {
	background: $gray;
	color: $white;
}

::selection {
	background: $gray;
	color: $white;
}

body {
	background-color: $gray--lightest;
	font-family: 'Montserrat', sans-serif !important;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: $gray;
	margin: 0;
	padding: 0;
}

img {
	margin: 0;
	display: block;
	user-select: none;
}

/* --- [ global styles ] --- */

.wrapper {
	width: 100%;
	overflow: hidden;
}

.container {
	max-width: 1440px;
	flex: 0 0 1440px;
	margin: 0 auto;
	padding: 0 75px;
	position: relative;
	@include media($w1366) {
		flex: 0 0 100%;
		padding: 0 50px;
	}
	@include media($w1024) {
		padding: 0 30px;
	}
	@include media($w768) {
		padding: 0 15px;
	}
}
