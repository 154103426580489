.contact-page {
  .map {
    height: 650px;
    margin: 100px 0;
    @include media($w1366) {
      height: 550px;
    }
    @include media($w1024) {
      height: 500px;
      margin: 80px 0;
    }
    @include media($w768) {
      height: 450px;
      margin: 60px 0;
    }
    @include media($w560) {
      height: 350px;
    }
  }
}
