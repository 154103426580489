.top-bar {
  background-color: $gray;
  position: relative;
  z-index: 50;
  @include media($w1024) {
    display: none;
  }
  &__inner {
    height: 50px;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    @include media($w1366) {
      height: 40px;
    }
    ul {
      justify-content: flex-end;
      display: flex;
      li {
        & + li {
          margin: 0 0 0 20px;
          @include media($w1024) {
            margin: 0 0 0 15px;
          }
        }
        a {
          font-size: 16px;
          line-height: 22px;
          color: $white;
          @include media($w1024) {
            font-size: 14px;
            line-height: 20px;
          }
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }
}
