.references {
  &__copy {
    margin: 80px 0;
    @include media($w768) {
      margin: 60px 0;
    }
    &-text {
      h2 {
        margin: 0 0 50px;
        @include media($w1024) {
          margin: 0 0 45px;
        }
        @include media($w768) {
          margin: 0 0 40px;
        }
      }
      P {
        font-family: 'Montserrat', sans-serif !important;
      	font-size: 24px;
      	font-weight: 300;
      	line-height: 36px;
        margin: 0 0 50px;
        @include media($w1366) {
      		font-size: 22px;
      		line-height: 30px;
      	}
        @include media($w1024) {
      		font-size: 20px;
      		line-height: 28px;
      	}
        @include media($w768) {
      		font-size: 18px;
      		line-height: 26px;
      	}
        @include media($w560) {
      		font-size: 16px;
      		line-height: 24px;
      	}
      }
      & > * {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
