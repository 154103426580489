.about-us-page {
  .our-solutions {
    margin: 120px 0;
    @include media($w1366) {
      margin: 100px 0;
    }
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    &__header {
      padding: 0 0 110px;
      display: flex;
      @include media($w1366) {
        padding: 0 0 95px;
      }
      @include media($w1024) {
        padding: 0 0 75px;
        flex-wrap: wrap;
      }
      @include media($w768) {
        padding: 0 0 55px;
      }
      &-left,
      &-right {
        flex: 0 0 50%;
        @include media($w1024) {
          flex: 0 0 100%;
        }
      }
      &-left {
        h2 {
          @include media($w1024) {
            margin: 0 0 30px;
          }
          @include media($w560) {
            margin: 0 0 25px;
          }
        }
      }
    }
    &__body {
      display: flex;
      @include media($w1024) {
        flex-wrap: wrap;
      }
      &-col {
        flex: 0 0 (100% / 3);
        padding: 65px 40px 35px;
        color: $white;
        @include media($w1366) {
          padding: 55px 30px 35px;
        }
        @include media($w1024) {
          flex: 0 0 (100% / 2);
        }
        @include media($w768) {
          flex: 0 0 100%;
          text-align: center;
        }
        &:nth-child(1) {
          background-color: $red;
          overflow: hidden;
          padding: 65px 75px 75px;
          align-items: center;
          display: flex;
          position: relative;
          @include media($w1366) {
            padding: 55px 50px 35px;
          }
          @include media($w1024) {
            flex: 0 0 100%;
            text-align: center;
            padding: 50px 30px;
            justify-content: center;
          }
          &:before {
            @include translateX;
            content: '';
            background-image: url(../images/home--about-us--bg.svg);
            background-size: cover;
            background-position: right center;
            width: 733px;
            height: 450px;
            pointer-events: none;
            position: absolute;
            z-index: 25;
            top: 0;
            left: calc(50% - 50px);
          }
        }
        &:nth-child(2) {
          background-color: $gray;
        }
        &:nth-child(3) {
          background-color: rgba($gray,0.65);
        }
      }
      &-years {
        font-family: 'Montserrat', sans-serif !important;
        font-size: 165px;
        font-weight: 700;
        line-height: 165px;
        margin: 0 0 -40px;
        position: relative;
        z-index: 50;
        @include media($w1366) {
          font-size: 140px;
          line-height: 140px;
        }
        @include media($w1200) {
          font-size: 125px;
          line-height: 125px;
        }
        @include media($w1024) {
          font-size: 110px;
          line-height: 110px;
          margin: 0 0 -20px;
        }
        @include media($w768) {
          font-size: 95px;
          line-height: 95px;
        }
        sub {
          font-family: 'Calibri' !important;
          font-size: 18%;
          @include media($w1366) {
            font-size: 20%;
          }
          @include media($w1200) {
            font-size: 22%;
          }
          @include media($w1024) {
            font-size: 25%;
          }
          @include media($w768) {
            font-size: 28%;
          }
        }
      }
      h3 {
        font-size: 39px;
        line-height: 43px;
        margin: 0 0 30px;
        position: relative;
        z-index: 50;
        @include media($w1200) {
          font-size: 34px;
          line-height: 40px;
        }
        @include media($w1024) {
          font-size: 32px;
          line-height: 38px;
          margin: 0;
        }
        @include media($w768) {
          font-size: 30px;
          line-height: 36px;
        }
        br {
          @include media($w1024) {
            display: none;
          }
        }
      }
      &-icon {
        margin: 0 0 40px;
        @include media($w768) {
          margin: 0 0 30px;
        }
        img {
          height: 62px;
          margin: 0 auto;
          @include media($w1366) {
            height: 56px;
          }
          @include media($w768) {
            height: 50px;
          }
        }
      }
      h4 {
        text-align: center;
        margin: 0 0 50px;
        @include media($w1366) {
          margin: 0 0 35px;
        }
        @include media($w768) {
          margin: 0 0 25px;
        }
      }
      &-link {
        text-align: center;
        margin: 40px 0 0;
        @include media($w1366) {
          margin: 35px 0 0;
        }
        @include media($w768) {
          margin: 25px 0 0;
        }
        a {
          font-family: 'Montserrat', sans-serif !important;
        	font-size: 12px;
        	font-weight: 300;
        	line-height: 18px;
          text-transform: uppercase;
          color: $white;
          padding: 0 0 0 15px;
          display: inline-block;
          position: relative;
          &:before {
            @include translateY;
            content: '';
            background-image: url(../images/icons/angle--next--red.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center left;
            width: 11px;
            height: 11px;
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
}
