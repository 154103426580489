@font-face {
font-family: 'Calibri';
src: url('../fonts/Calibri-Bold.eot');
src: url('../fonts/Calibri-Bold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/Calibri-Bold.woff2') format('woff2'),
		 url('../fonts/Calibri-Bold.woff') format('woff'),
		 url('../fonts/Calibri-Bold.ttf') format('truetype');
font-weight: 700;
font-style: normal;
}
