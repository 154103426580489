input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
	@include transition;
	border: 0;
	border-radius: 5px;
	background-color: $gray--lightest;
	width: 100%;
	height: 53px;
	font-family: 'Montserrat', sans-serif !important;
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	color: $black;
	outline: none;
	margin: 0;
	padding: 0 25px;
	box-shadow: none !important;
	-webkit-appearance: none;
	&:focus {
		background-color: $white;
	}
}

input[type="submit"] {
	@include transition;
	border: 0;
	background-color: $gray--light;
	min-width: 178px;
	height: 57px;
	font-family: 'Montserrat', sans-serif !important;
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	color: $gray;
	cursor: pointer;
	margin: 0;
	padding: 0 20px;
	justify-content: center;
	align-items: center;
	display: inline-flex;
	&:hover {
		background-color: $gray--dark;
			color: $white;
	}
}

textarea {
	height: 143px;
	resize: none;
	padding: 15px 25px;
}

::-webkit-input-placeholder {
	color: $gray !important;
	opacity: 1;
}

:-moz-placeholder {
	color: $gray !important;
	opacity: 1;
}

::-moz-placeholder {
	color: $gray !important;
	opacity: 1;
}

:-ms-input-placeholder {
	color: $gray !important;
	opacity: 1;
}

button {
	border: 0;
	border-radius: 0;
	background-color: transparent;
	font-family: 'Calibri' !important;
	cursor: pointer;
	margin: 0;
	-webkit-appearance: none;
}

.inputs {
	margin: 0 0 20px;
	justify-content: space-between;
	display: flex;
	@include media($w560) {
		flex-wrap: wrap;
		margin: 0;
	}
	&__item {
		flex: 0 0 calc(50% - 10px);
		@include media($w560) {
			flex: 0 0 100%;
			margin: 0 0 15px;
		}
	}
}

.textarea {
	margin: 0 0 25px;
}

.submit {
	justify-content: center;
	display: flex;
}

.wpcf7-not-valid-tip {
	font-family: 'Montserrat', sans-serif !important;
	font-size: 12px !important;
	line-height: 20px !important;
	color: $white !important;
	margin: 5px 0 0 !important;
	display: block !important;
}

.wpcf7-validation-errors {
	font-family: 'Montserrat', sans-serif !important;
	font-size: 14px !important;
	line-height: 22px !important;
	text-align: center !important;
	margin: 25px 0 0 !important;
	padding: 12px 20px !important;
}

.ajax-loader {
	display: none !important;
}
