.statistics {
  margin: 120px 0;
  @include media($w1366) {
    margin: 100px 0;
  }
  @include media($w1024) {
    margin: 80px 0;
  }
  @include media($w768) {
    margin: 60px 0;
  }
  &__header {
    margin: 0 0 40px;
  }
  &__stats {
    align-items: center;
    display: flex;
    @include media($w1024) {
      margin: 0 0 -30px;
      flex-wrap: wrap;
    }
    &-col {
      flex: 0 0 25%;
      align-items: center;
      display: flex;
      @include media($w1024) {
        flex: 0 0 50%;
        margin: 0 0 30px;
      }
      @include media($w560) {
        flex: 0 0 100%;
      }
      &-icon {
        height: 70px;
        margin: 0 20px 0 0;
        align-items: center;
        display: flex;
        @include media($w1366) {
          width: 80px;
          height: 62px;
        }
        @include media($w768) {
          width: 70px;
          height: 54px;
        }
        img {
          max-width: 90px;
          max-height: 70px;
          @include media($w1366) {
            max-width: 80px;
            max-height: 62px;
      		}
          @include media($w768) {
            max-width: 70px;
            max-height: 54px;
      		}
        }
      }
      &-text {
        flex: 1 1 auto;
        font-size: 16px;
        line-height: 24px;
        padding: 0 0 8px;
        strong {
          font-size: 60px;
          line-height: 60px;
          color: $red;
          @include media($w1366) {
      			font-size: 50px;
      			line-height: 50px;
      		}
      		@include media($w1024) {
      			font-size: 44px;
      			line-height: 44px;
      		}
      		@include media($w768) {
      			font-size: 38px;
      			line-height: 38px;
      		}
        }
        span {
          font-family: 'Calibri' !important;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          sup {
            font-size: 70%;
            position: relative;
            bottom: 3px;
          }
        }
      }
    }
  }
}
