.home-page {
  .adaptation {
    margin: 120px 0;
    @include media($w1366) {
      margin: 100px 0;
    }
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    &__inner {
      padding: 0 0 120px;
      display: flex;
      @include media($w1366) {
        padding: 0 0 95px;
      }
      @include media($w1024) {
        padding: 0 0 75px;
        flex-wrap: wrap;
      }
      @include media($w768) {
        padding: 0 0 55px;
      }
    }
    &__copy {
      flex: 0 0 50%;
      padding: 0 120px 0 0;
      @include media($w1366) {
        padding: 0 50px 0 0;
      }
      @include media($w1024) {
        flex: 0 0 100%;
        padding: 0;
      }
      h2 {
        margin: 0 0 40px;
        @include media($w1024) {
          margin: 0 0 30px;
        }
      }
      h3 {
        font-family: 'Montserrat', sans-serif !important;
        font-size: 24px;
      	font-weight: 300;
        line-height: 36px;
        margin: 0 0 50px;
        @include media($w1366) {
          font-size: 22px;
          line-height: 34px;
          margin: 0 0 40px;
        }
        @include media($w1024) {
          font-size: 20px;
          line-height: 30px;
          margin: 0 0 30px;
        }
      }
      &-block {
        margin: 0 0 50px;
        @include media($w1366) {
          margin: 0 0 40px;
        }
        @include media($w1024) {
          margin: 0 0 30px;
        }
        &:last-of-type {
          margin: 0;
        }
        h4 {
          margin: 0 0 25px;
        }
      }
    }
    &__image {
      background-size: cover;
      background-position: center center;
      flex: 0 0 50%;
      @include media($w1024) {
        flex: 0 0 100%;
      }
    }
    &__panels {
      padding: 75px 0 0;
      align-items: flex-end;
      display: flex;
      position: relative;
      @include media($w1366) {
        padding: 60px 0 0;
      }
      &-bgs {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        position: absolute;
        z-index: 25;
        top: 0;
        left: 0;
        &-inner {
          width: 300%;
          height: 100%;
          display: flex;
          transition: left 0.4s ease 0s;
          position: absolute;
          top: 0;
        }
        &-item {
          background-size: cover;
          background-position: center center;
          flex: 0 0 calc(100% / 3);
        }
      }
      &-inner {
        min-height: 600px;
        align-items: flex-end;
        display: flex;
        position: relative;
        z-index: 50;
        @include media($w1366) {
          min-height: 500px;
        }
        @include media($w1024) {
          min-height: 450px;
        }
        @include media($w768) {
          min-height: 375px;
        }
        @include media($w560) {
          min-height: 300px;
          margin: 0 -15px;
        }
      }
      &-item {
        @include transition;
        background-color: rgba($black,0.6);
        flex: 0 0 calc(100% / 3);
        color: $white;
        padding: 60px 0 0 60px;
        @include media($w1366) {
          padding: 40px 0 0 30px;
        }
        @include media($w768) {
          padding: 30px 15px 0;
        }
        @include media($w560) {
          flex: 0 0 calc(100% / 3 + 10px);
          padding: 25px 15px 0;
        }
        &-number {
          height: 80px;
          margin: 0 0 20px;
          position: relative;
          @include media($w1366) {
            height: 70px;
          }
          @include media($w1024) {
            height: 60px;
          }
          @include media($w768) {
            height: 50px;
            margin: 0 0 15px;
          }
          @include media($w560) {
            height: 40px;
          }
          img {
            @include transition;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            &.active {
              opacity: 0;
            }
          }
        }
        h3 {
          @include transition;
          margin: 0 0 55px;
          @include media($w1366) {
            font-size: 22px;
            line-height: 26px;
            margin: 0 0 35px;
          }
          @include media($w1024) {
            font-size: 18px;
            line-height: 22px;
            margin: 0 0 25px;
          }
          @include media($w560) {
            font-size: 16px;
            line-height: 18px;
          }
          @include media($w560) {
            font-size: 13px;
            line-height: 16px;
            margin: 0 0 20px;
          }
          br {
            display: none;
            @include media($w768) {
              display: block;
            }
          }
        }
        &-desc {
          display: none;
          p {
            padding: 0 60px 60px 0;
            transition: top 0.4s ease 0s;
            position: relative;
            top: 50px;
            @include media($w1366) {
              padding: 0 30px 40px 0;
            }
            @include media($w1024) {
              font-size: 15px;
              line-height: 21px;
              padding: 0 30px 35px 0;
            }
            @include media($w768) {
              display: none !important;
            }
          }
        }
        &.active {
          background-color: $red;
          pointer-events: none;
          h3 {
            margin: 0 0 45px;
            @include media($w1366) {
              margin: 0 0 25px;
            }
            @include media($w560) {
              margin: 0 0 20px;
            }
          }
          .adaptation {
            &__panels {
              &-item {
                &-number {
                  img {
                    opacity: 0;
                    &.active {
                      opacity: 1;
                    }
                  }
                }
                &-desc {
                  p {
                    transition: top 0.4s ease 0.1s;
                    top: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
