.bottom {
  background-color: $gray--darkest;
  color: $white;
  overflow: hidden;
  position: relative;
  &__bg {
    background-size: cover;
    background-position: center center;
    width: 1304px;
    height: 805px;
    opacity: 0.075;
    pointer-events: none;
    position: absolute;
    z-index: 25;
    top: -65px;
    right: -450px;
  }
  a {
    color: $white;
    &:hover {
      opacity: 0.6;
    }
  }
  .container {
    z-index: 50;
  }
  &__menu {
    border-bottom: 1px solid rgba($white,0.1);
    padding: 80px 0 70px;
    display: flex;
    @include media($w1366) {
      padding: 65px 0 60px;
    }
    @include media($w1024) {
      padding: 50px 0 45px;
      flex-wrap: wrap;
    }
    @include media($w768) {
      padding: 45px 0 40px;
    }
    &-col {
      flex: 0 0 50%;
      @include media($w1024) {
        flex: 0 0 100%;
      }
      & + .bottom {
        &__menu {
          &-col {
            @include media($w1024) {
              margin: 15px 0 0;
            }
          }
        }
      }
      ul {
        margin: 0 0 -6px;
        flex-wrap: wrap;
        display: flex;
        @include media($w1366) {
          margin: 0 0 -4px;
        }
        @include media($w560) {
          margin: 0;
        }
        li {
          flex: 0 0 50%;
          font-family: 'Montserrat', sans-serif !important;
          font-size: 16px;
          font-weight: 300;
          line-height: 30px;
          margin: 0 0 6px;
          @include media($w1024) {
            font-size: 14px;
            line-height: 28px;
            margin: 0 0 4px;
          }
          @include media($w560) {
            font-size: 13px;
            line-height: 26px;
            margin: 0;
          }
        }
      }
    }
  }
  &__logo {
    padding: 40px 0 36px;
    @include media($w560) {
      padding: 35px 0 31px;
    }
    img {
      width: 215px;
      @include media($w1366) {
        width: 180px;
      }
      @include media($w768) {
        width: 166px;
      }
      @include media($w560) {
        width: 150px;
      }
    }
  }
  &__data {
    padding: 0 0 65px;
    display: flex;
    @include media($w1366) {
      padding: 0 0 60px;
    }
    @include media($w1024) {
      flex-wrap: wrap;
      margin: 0 0 -15px;
      padding: 0 0 40px;
    }
    @include media($w768) {
      padding: 0 0 35px;
    }
    &-col {
      flex: 0 0 25%;
      @include media($w1024) {
        flex: 0 0 50%;
      }
      p {
        line-height: 30px;
        @include media($w1024) {
          font-size: 14px;
          line-height: 28px;
          margin: 0 0 15px;
        }
        @include media($w560) {
          font-size: 13px;
          line-height: 26px;
        }
      }
    }
  }
}
