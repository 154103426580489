.home-page {
  .configurator {
    margin: 0 0 100px;
    @include media($w1366) {
      margin: 0 0 80px;
    }
    @include media($w768) {
      width: calc(100% + 30px);
      margin: 0 -15px 60px;
    }
    &__inner {
      background-color: $gray--light;
      height: 700px;
      @include media($w1024) {
        height: 650px;
      }
    }
  }
}
