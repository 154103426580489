a {
	@include transition;
	text-decoration: none;
	color: $red;
	&:hover {
		color: $gray;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Calibri' !important;
	font-weight: 700;
	margin: 0;
}

h1 {
	font-size: 100px;
	line-height: 100px;
	@include media($w1366) {
		font-size: 90px;
		line-height: 90px;
	}
	@include media($w1024) {
		font-size: 75px;
		line-height: 75px;
	}
	@include media($w768) {
		font-size: 62px;
		line-height: 62px;
	}
	@include media($w560) {
		font-size: 50px;
		line-height: 50px;
	}
	&.concept-heading {
		font-size: 60px;
		line-height: 60px;
		@include media($w1366) {
			font-size: 50px;
			line-height: 50px;
		}
		@include media($w1024) {
			font-size: 44px;
			line-height: 44px;
		}
		@include media($w768) {
			font-size: 38px;
			line-height: 38px;
		}
		@include media($w560) {
			font-size: 32px;
			line-height: 32px;
		}
	}
}

h2 {
	font-size: 60px;
	line-height: 60px;
	@include media($w1366) {
		font-size: 50px;
		line-height: 50px;
	}
	@include media($w1024) {
		font-size: 44px;
		line-height: 44px;
	}
	@include media($w768) {
		font-size: 38px;
		line-height: 38px;
	}
	@include media($w560) {
		font-size: 32px;
		line-height: 32px;
	}
}

h3 {
	font-size: 30px;
	line-height: 30px;
	@include media($w1366) {
		font-size: 28px;
		line-height: 28px;
	}
	@include media($w1024) {
		font-size: 26px;
		line-height: 26px;
	}
	@include media($w768) {
		font-size: 24px;
		line-height: 24px;
	}
	@include media($w560) {
		font-size: 22px;
		line-height: 22px;
	}
}

h4 {
	font-size: 24px;
	line-height: 28px;
	@include media($w1366) {
		font-size: 22px;
		line-height: 26px;
	}
	@include media($w1024) {
		font-size: 20px;
		line-height: 24px;
	}
}

h5 {
	font-size: 20px;
	line-height: 24px;
	@include media($w768) {
		font-size: 18px;
		line-height: 22px;
	}
}

h6 {
	font-size: 12px;
	line-height: 16px;
}

p {
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	margin: 0;
	@include media($w1024) {
		font-size: 15px;
		line-height: 22px;
	}
}

strong, b {
	font-weight: 700;
}

.c {
	strong {
		font-family: 'Montserrat', sans-serif !important;
	}
	h3, h4, h5, h6 {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 300;
		line-height: 130%;
		margin: 0 0 30px;
		@include media($w560) {
			margin: 0 0 20px;
		}
	}
	p {
		margin: 0 0 30px;
		@include media($w560) {
			margin: 0 0 20px;
		}
	}
	ul,
	ol {
		margin: 0 0 30px;
		@include media($w560) {
			margin: 0 0 20px;
		}
		li {
			font-size: 16px;
			font-weight: 300;
			line-height: 24px;
			padding: 0 0 0 20px;
			position: relative;
			@include media($w1024) {
				font-size: 15px;
				line-height: 22px;
			}
			&:before {
				content: '';
				background-image: url(../images/icons/angle--next--red.svg);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: left center;
				width: 12px;
				height: 12px;
				position: absolute;
				top: 6px;
				left: 0;
			}
		}
	}
	& > * {
		&:last-child {
			margin: 0 !important;
		}
	}
	&--post {
		h4 {
			line-height: 140%;
			margin: 0 0 40px;
			@include media($w1024) {
				margin: 0 0 30px;
			}
			@include media($w560) {
				margin: 0 0 20px;
			}
		}
		h3, h5, h6 {
			line-height: 180%;
			margin: 0 0 40px;
			@include media($w1024) {
				margin: 0 0 30px;
			}
			@include media($w560) {
				margin: 0 0 20px;
			}
		}
		p {
			line-height: 180%;
			margin: 0 0 40px;
			@include media($w1024) {
				margin: 0 0 30px;
			}
			@include media($w560) {
				margin: 0 0 20px;
			}
		}
		ul,
		ol {
			margin: 0 0 34px;
			@include media($w1024) {
				margin: 0 0 25px;
			}
			@include media($w560) {
				margin: 0 0 18px;
			}
			li {
				line-height: 180%;
			}
		}
		blockquote {
			background-color: $gray;
			color: $white;
			margin: 50px 0;
			padding: 90px 100px;
			display: block;
			clip-path: polygon(60px 0,100% 0,100% 100%,0 100%,0 60px);
			@include media($w1366) {
				padding: 70px;
				clip-path: polygon(50px 0,100% 0,100% 100%,0 100%,0 50px);
			}
			@include media($w1200) {
				padding: 60px;
			}
			@include media($w1024) {
				clip-path: polygon(40px 0,100% 0,100% 100%,0 100%,0 40px);
				margin: 40px 0;
				padding: 50px;
			}
			@include media($w768) {
				padding: 40px;
			}
			@include media($w560) {
				margin: 30px 0;
				padding: 40px 30px;
			}
			p {
				font-size: 28px;
				font-weight: 600;
				line-height: 34px;
				margin: 0;
				@include media($w1366) {
					font-size: 26px;
					line-height: 32px;
				}
				@include media($w1024) {
					font-size: 24px;
					line-height: 30px;
				}
				@include media($w768) {
					font-size: 22px;
					line-height: 28px;
				}
				@include media($w560) {
					font-size: 20px;
					line-height: 26px;
				}
			}
		}
		figure {
			padding: 10px 0 50px;
			@include media($w560) {
				padding: 10px 0 40px;
			}
			@include media($w560) {
				padding: 10px 0 30px;
			}
			img {
				max-width: 100% !important;
				width: auto !important;
				height: auto !important;
				margin: 0 auto !important;
			}
		}
	}
}
