.footer {
  background-color: $black;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  color: $white;
  padding: 26px 0 25px;
  @include media($w1366) {
    font-size: 15px;
    line-height: 28px;
  }
  @include media($w1024) {
    font-size: 14px;
    line-height: 26px;
    padding: 23px 0 22px;
  }
  @include media($w768) {
    padding: 20px 0;
  }
  @include media($w560) {
    line-height: 22px;
  }
  &__inner {
    opacity: 0.6;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  &__copy {
    margin: 0 30px 0 0;
    @include media($w768) {
      margin: 0 15px 0 0;
    }
  }
  &__author {
    align-items: center;
    display: flex;
    a {
      margin: 0 0 0 15px;
      img {
        width: 140px;
        @include media($w1366) {
          width: 130px;
        }
        @include media($w1024) {
          width: 120px;
        }
      }
    }
  }
}
