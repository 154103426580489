.blog-page {
  .pagination {
    margin: 100px 0;
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    @include media($w560) {
      margin: 50px 0 60px;
    }
    ul {
      justify-content: center;
      display: flex;
      li {
        a {
          min-width: 30px;
          height: 30px;
          font-family: 'Montserrat', sans-serif !important;
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          padding: 0 10px;
          justify-content: center;
          align-items: center;
          display: inline-flex;
        }
        a {
          color: $gray;
          &:hover {
            color: $red;
          }
        }
        &.current {
          a {
            background-color: $red;
            color: $white;
            pointer-events: none;
          }
        }
        &.prev,
        &.next {
          a {
            font-size: 22px;
            padding: 0 10px 3px;
          }
        }
        &.prev {
          margin: 0 10px 0 0;
        }
        &.next {
          margin: 0 0 0 10px;
        }
      }
    }
  }
}
