.home-page {
  .partners {
    position: relative;
    &:after {
      content: '';
      background-color: $white;
      width: 50%;
      height: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 50%;
    }
    &__carousel {
      background-color: $white;
      padding: 60px 30px;
      clip-path: polygon(80px 0,100% 0,100% 100%,0 100%,0 80px);
      @include media($w1366) {
        padding: 55px 30px;
        clip-path: polygon(40px 0,100% 0,100% 100%,0 100%,0 50px);
      }
      @include media($w1024) {
        padding: 50px 30px;
        clip-path: polygon(40px 0,100% 0,100% 100%,0 100%,0 40px);
      }
      @include media($w768) {
        padding: 45px 30px;
      }
      &-item {
        img {
          height: 80px;
          margin: 0 auto;
          @include media($w1366) {
            height: 75px;
          }
          @include media($w1024) {
            height: 70px;
          }
          @include media($w768) {
            height: 65px;
          }
          @include media($w560) {
            height: 50px;
          }
        }
      }
    }
  }
}
