.breadcrumbs {
  flex: 0 0 100%;
  justify-content: center;
  display: flex;
  @include media($w560) {
    display: none;
  }
  span {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    text-transform: uppercase;
    color: $white;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    &:before {
      @include translateY;
      background-image: url(../images/icons/angle--next.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: 9px;
      height: 9px;
      position: absolute;
      top: calc(50% - 1px);
      left: -13px;
    }
    & + span {
      margin: 0 0 0 17px !important;
      &:before {
        content: '';
      }
    }
    a {
      color: $white;
      &:hover {
        opacity: 0.75;
      }
    }
  }
}
