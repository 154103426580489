.reference {
  &__body {
    margin: 80px 0 100px;
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0 55px;
    }
    &-header {
      text-transform: uppercase;
      margin: 0 0 70px;
      @include media($w768) {
        margin: 0 0 55px;
      }
    }
    &-logo {
      background-color: $white;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 487px;
      margin: 0 0 80px;
      box-shadow: 0px 20px 25px -5px rgba($black,0.05);
      @include media($w1366) {
        height: 460px;
      }
      @include media($w1200) {
        height: 420px;
      }
      @include media($w1024) {
        height: 350px;
        margin: 0 0 70px;
        box-shadow: 0px 15px 20px -3px rgba($black,0.05);
      }
      @include media($w768) {
        height: 300px;
        margin: 0 0 55px;
      }
      @include media($w560) {
        height: 250px;
        box-shadow: 0px 10px 15px -2px rgba($black,0.05);
      }
    }
    &-text {
      P {
        font-family: 'Montserrat', sans-serif !important;
      	font-size: 24px;
      	font-weight: 300;
      	line-height: 36px;
        margin: 0 0 50px;
        @include media($w1366) {
      		font-size: 22px;
      		line-height: 30px;
          margin: 0 0 40px;
      	}
        @include media($w1024) {
      		font-size: 20px;
      		line-height: 28px;
          margin: 0 0 35px;
      	}
        @include media($w768) {
      		font-size: 18px;
      		line-height: 26px;
          margin: 0 0 30px;
      	}
        @include media($w560) {
      		font-size: 16px;
      		line-height: 24px;
          margin: 0 0 20px;
      	}
      }
      & > * {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
