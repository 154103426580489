.post-page {
  .post {
    &__sidebar {
      flex: 0 0 480px;
      padding: 0 0 0 100px;
      @include media($w1366) {
        flex: 0 0 440px;
        padding: 0 0 0 50px;
      }
      @include media($w1200) {
        flex: 0 0 400px;
        padding: 0 0 0 30px;
      }
      @include media($w1024) {
        flex: 0 0 100%;
        padding: 50px 0 0;
        justify-content: space-between;
        align-items: flex-start;
        display: flex;
      }
      @include media($w768) {
        display: block;
      }
      &-panel {
        background-color: $white;
        clip-path: polygon(40px 0,100% 0,100% 100%,0 100%,0 40px);
        padding: 50px 40px 40px;
        @include media($w1366) {
          padding: 45px 30px 30px;
        }
        @include media($w1024) {
          flex: 0 0 calc(50% - 15px);
          padding: 40px 30px 30px;
        }
        & + .post {
          &__sidebar {
            &-panel {
              margin: 30px 0 0;
              @include media($w1024) {
                margin: 0;
              }
              @include media($w768) {
                margin: 30px 0 0;
              }
            }
          }
        }
        h4 {
          margin: 0 0 42px;
          @include media($w1366) {
            margin: 0 0 35px;
          }
          @include media($w1024) {
            margin: 0 0 30px;
          }
        }
        &--categories {
          & > ul {
            margin: 0 0 15px;
            @include media($w1024) {
              margin: 0 0 10px;
            }
            li {
              &.categories {
                ul {
                  li {
                    & + .post {
                      &__sidebar {
                        &-categories {
                          &-item {
                            margin: 2px 0 0;
                          }
                        }
                      }
                    }
                    a {
                      font-family: 'Montserrat', sans-serif !important;
                    	font-size: 16px;
                    	font-weight: 300;
                    	line-height: 30px;
                      color: $gray;
                      padding: 0 0 0 15px;
                      display: inline-block;
                      position: relative;
                      @include media($w1024) {
                        font-size: 14px;
                      	line-height: 26px;
                      }
                      &:before {
                        @include translateY;
                        content: '';
                        background-image: url(../images/icons/angle--next--red.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center left;
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        top: calc(50% + 1px);
                        left: 0;
                      }
                      &:hover {
                        color: $red;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &-posts {
        padding: 10px 0 0;
        &-item {
          align-items: center;
          display: flex;
          & + .post {
            &__sidebar {
              &-posts {
                &-item {
                  margin: 30px 0 0;
                  @include media($w1366) {
                    margin: 25px 0 0;
                  }
                }
              }
            }
          }
          &-image {
            background-size: cover;
            background-position: center center;
            flex: 0 0 79px;
            min-height: 82px;
            position: relative;
            &-click {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          &-copy {
            flex: 1 1 auto;
            padding: 0 0 0 20px;
            h5 {
              margin: 0 0 5px;
              a {
                color: $gray;
                &:hover {
                  color: $red;
                }
              }
            }
            &-data {
              justify-content: space-between;
              align-items: center;
              display: flex;
              a {
                font-family: 'Montserrat', sans-serif !important;
                font-size: 12px;
                font-weight: 300;
                line-height: 24px;
                padding: 0 0 0 15px;
                display: inline-block;
                position: relative;
                &:before {
                  @include translateY;
                  content: '';
                  background-image: url(../images/icons/angle--next--red.svg);
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center left;
                  width: 10px;
                  height: 10px;
                  position: absolute;
                  top: 50%;
                  left: 0;
                }
              }
              time {
                font-family: 'Montserrat', sans-serif !important;
              	font-size: 12px;
              	font-weight: 300;
              	line-height: 24px;
                opacity: 0.75;
              }
            }
          }
        }
      }
    }
  }
}
