.about-us-page {
  .why-us {
    margin: 120px 0;
    @include media($w1366) {
      margin: 100px 0;
    }
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    &__header {
      padding: 0 0 105px;
      @include media($w1366) {
        padding: 0 0 95px;
      }
      @include media($w1024) {
        padding: 0 0 75px;
      }
      @include media($w768) {
        padding: 0 0 55px;
      }
    }
    &__body {
      &-item {
        justify-content: flex-end;
        display: flex;
        position: relative;
        &:before {
          content: '';
          background-color: $white;
          width: calc(33.5% + 20px);
          height: 80px;
          box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.1);
          position: absolute;
          z-index: 50;
          bottom: 130px;
          right: 30px;
          @include media($w768) {
            display: none;
          }
        }
        & + .why-us {
          &__body {
            &-item {
              margin: 160px 0 0;
              @include media($w1366) {
                margin: 95px 0 0;
              }
              @include media($w1024) {
                margin: 75px 0 0;
              }
              @include media($w768) {
                margin: 55px 0 0;
              }
            }
          }
        }
        &-image {
          background-size: cover;
          background-position: center center;
          width: 66.5%;
          height: 100%;
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          @include media($w768) {
            width: 100%;
            transition: all 0.4s ease 0s !important;
            left: -15px;
          }
        }
        &-copy {
          background-color: $white;
          flex: 0 0 calc(33.5% + 80px);
          margin: 130px 0;
          padding: 80px;
          clip-path: polygon(50px 0,100% 0,100% 100%,0 100%,0 50px);
          box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.16);
          position: relative;
          z-index: 75;
          @include media($w1366) {
            margin: 110px 0;
            padding: 80px 50px;
            clip-path: polygon(40px 0,100% 0,100% 100%,0 100%,0 40px);
          }
          @include media($w1024) {
            flex: 0 0 45%;
            margin: 80px 0;
            padding: 50px 30px;
            clip-path: polygon(30px 0,100% 0,100% 100%,0 100%,0 30px);
          }
          @include media($w768) {
            flex: 0 0 calc(100% - 15px);
            margin: 60px -15px 60px 0;
          }
          h4 {
            margin: 0 0 50px;
            @include media($w1366) {
              margin: 0 0 40px;
            }
            @include media($w1024) {
              margin: 0 0 30px;
            }
          }
          p {
            @include media($w768) {
              font-size: 12px;
              line-height: 22px;
            }
          }
        }
        &:nth-child(2n+2) {
          justify-content: flex-start;
          @include media($w768) {
            justify-content: flex-end;
          }
          &:before {
            right: auto;
            left: 30px;
          }
          .why-us {
            &__body {
              &-item {
                &-image {
                  left: auto;
                  right: 0;
                  @include media($w768) {
                    left: -15px;
                    right: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
