.about-us-page {
  .quality {
    margin: -110px 0 0;
    position: relative;
    z-index: 50;
    @include media($w1366) {
      margin: -50px 0 0;
    }
    @include media($w1024) {
      margin: -40px 0 0;
    }
    @include media($w768) {
      margin: 60px 0 0;
    }
    &__inner {
      display: flex;
      @include media($w1024) {
        flex-wrap: wrap;
      }
    }
    &__copy {
      background-color: $white;
      flex: 0 0 58%;
      min-height: 760px;
      padding: 80px;
      clip-path: polygon(75px 0,100% 0,100% 100%,0 100%,0 75px);
      position: relative;
      @include media($w1366) {
        flex: 0 0 55%;
        min-height: 660px;
        padding: 60px 50px;
        clip-path: polygon(50px 0,100% 0,100% 100%,0 100%,0 50px);
      }
      @include media($w1024) {
        flex: 0 0 100%;
        min-height: 1px;
        padding: 50px 30px;
        clip-path: polygon(40px 0,100% 0,100% 100%,0 100%,0 40px);
      }
      &:before {
        @include translateX;
        content: '';
        background-image: url(../images/home--about-us--bg.svg);
        background-size: cover;
        background-position: right center;
        width: 1402px;
        height: 866px;
        opacity: 0.1;
        pointer-events: none;
        position: absolute;
        z-index: 25;
        top: -30px;
        left: calc(50% - 100px);
        @include media($w1024) {
          width: 1000px;
          height: 618px;
          left: 30%;
        }
        @include media($w560) {
          width: 800px;
          height: 494px;
          left: 35%;
        }
      }
      &-inner {
        position: relative;
        z-index: 50;
        h2 {
          margin: 0 0 50px;
          @include media($w1024) {
            margin: 0 0 40px;
          }
          @include media($w560) {
            margin: 0 0 35px;
          }
        }
      }
    }
    &__image {
      background-size: cover;
      background-position: center center;
      flex: 0 0 42%;
      @include media($w1366) {
        flex: 0 0 45%;
      }
      @include media($w1024) {
        flex: 0 0 100%;
        height: 550px;
      }
      @include media($w768) {
        height: 500px;
      }
      @include media($w560) {
        height: 450px;
      }
    }
  }
}
