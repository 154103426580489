.page-label {
  margin: 0 0 40px;
  @include media($w1024) {
    margin: 0 0 35px;
  }
  h6 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 300;
    text-transform: uppercase;
    color: $white;
    padding: 0 0 0 18px;
    position: relative;
    &:before {
      @include translateY;
      content: '';
      background-image: url(../images/icons/angle--next.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
      width: 11px;
      height: 11px;
      position: absolute;
      left: 0;
    }
  }
  &--dark {
    h6 {
      color: $gray;
      &:before {
        background-image: url(../images/icons/angle--next--red.svg);
      }
    }
  }
  &--center {
    justify-content: center;
    display: flex;
  }
  &--small {
    margin: 0 0 25px;
  }
  &--large {
    margin: 0 0 65px;
  }
  &--no {
    margin: 0;
  }
}
