.contact-page {
  .data {
    margin: 100px 0;
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    &__header {
      margin: 0 0 50px;
      @include media($w1024) {
        margin: 0 0 40px;
      }
    }
    &__body {
      p {
        font-family: 'Montserrat', sans-serif !important;
      	font-size: 20px;
      	font-weight: 300;
      	line-height: 30px;
        @include media($w1024) {
          font-size: 18px;
        	line-height: 26px;
        }
        @include media($w768) {
          font-size: 16px;
        	line-height: 24px;
        }
      }
      ul {
        li {
          font-family: 'Montserrat', sans-serif !important;
        	font-size: 20px;
        	font-weight: 300;
        	line-height: 30px;
          @include media($w1024) {
            font-size: 18px;
          	line-height: 26px;
          }
          @include media($w768) {
            font-size: 16px;
          	line-height: 24px;
          }
        }
      }
      a {
        color: $gray;
        &:hover {
          color: $red;
        }
      }
      & > p {
        margin: 0 0 30px;
      }
      &-inner {
        flex-wrap: wrap;
        display: flex;
      }
      &-contact {
        flex: 0 0 50%;
        @include media($w1024) {
          flex: 0 0 100%;
          margin: 0 0 40px;
        }
        @include media($w768) {
          margin: 0 0 30px;
        }
        ul {
          margin: 0 0 50px;
          @include media($w1024) {
            margin: 0 0 40px;
          }
          @include media($w768) {
            margin: 0 0 30px;
          }
          li {
            strong {
              width: 160px;
              font-weight: 300;
              display: inline-block;
              @include media($w768) {
                width: 120px;
              }
              @include media($w560) {
                width: 100%;
                font-weight: 600;
                display: block;
              }
            }
          }
        }
      }
      &-banking {
        flex: 0 0 50%;
        @include media($w1024) {
          flex: 0 0 100%;
        }
        ul {
          li {
            strong {
              width: 100px;
              font-weight: 300;
              display: inline-block;
              @include media($w768) {
                width: 80px;
              }
              @include media($w560) {
                width: 100%;
                font-weight: 600;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
