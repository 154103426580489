.realizations {
  margin: 120px 0;
  @include media($w1366) {
    margin: 100px 0;
  }
  @include media($w1024) {
    margin: 80px 0;
  }
  @include media($w768) {
    margin: 60px 0;
  }
  &__header {
    text-align: center;
    margin: 0 0 110px;
    @include media($w1366) {
      margin: 0 0 95px;
    }
    @include media($w1024) {
      margin: 0 0 75px;
    }
    @include media($w768) {
      margin: 0 0 55px;
    }
  }
  &__body {
    flex-wrap: wrap;
    display: flex;
    @include media($w560) {
      width: calc(100% + 30px);
      margin: 0 -15px;
    }
    &-item {
      flex: 0 0 calc(100% / 3);
      height: 410px;
      overflow: hidden;
      position: relative;
      @include media($w1366) {
        height: 370px;
      }
      @include media($w1024) {
        flex: 0 0 calc(100% / 2);
        height: 330px;
      }
      @include media($w768) {
        height: 290px;
      }
      @include media($w560) {
        flex: 0 0 100%;
        height: 250px;
      }
      &:nth-child(5n+2) {
        flex: 0 0 calc((100% / 3) * 2);
        @include media($w1024) {
          flex: 0 0 calc(100% / 2);
        }
        @include media($w560) {
          flex: 0 0 100%;
        }
      }
      a {
        color: $white;
      }
      &-image {
        @include transition;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      &-overlay {
        @include transition;
        background-color: rgba($black,0.4);
        width: 100%;
        height: 100%;
        color: $white;
        opacity: 0;
        align-items: flex-end;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        @include media($w768) {
          opacity: 1;
        }
        &-name {
          @include transition;
          width: 100%;
          height: calc(50% - 40px);
          opacity: 0;
          overflow: hidden;
          position: absolute;
          top: 50%;
          left: 0;
          @include media($w1024) {
            height: calc(50% - 30px);
          }
          @include media($w768) {
            opacity: 1;
          }
          @include media($w560) {
            height: calc(50% - 25px);
          }
          h4 {
            @include transition;
            width: 100%;
            padding: 0 50px;
            position: absolute;
            bottom: -100%;
            @include media($w1024) {
              padding: 0 30px;
            }
            @include media($w768) {
              bottom: 0;
            }
          }
        }
      }
      &:hover {
        .realizations {
          &__body {
            &-item {
              &-image {
                width: 110%;
                height: 110%;
                top: -5%;
                left: -5%;
              }
              &-overlay {
                opacity: 1;
                &-name {
                  opacity: 1;
                  h4 {
                    bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &--page {
    margin: 80px 0 120px;
    @include media($w1366) {
      margin: 70px 0 100px;
    }
    @include media($w1024) {
      margin: 60px 0 80px;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    .page-label {
      @include media($w768) {
        justify-content: center;
        display: flex;
      }
    }
    .realizations {
      &__body {
        &-item {
          flex: 0 0 calc(100% / 3);
          @include media($w1024) {
            flex: 0 0 calc(100% / 2);
          }
          @include media($w560) {
            flex: 0 0 100%;
          }
          &:nth-child(5n+2) {
            flex: 0 0 calc(100% / 3);
            @include media($w1024) {
              flex: 0 0 calc(100% / 2);
            }
            @include media($w560) {
              flex: 0 0 100%;
            }
          }
        }
      }
      &__categories {
        padding: 70px 0 90px;
        @include media($w1366) {
          padding: 50px 0 80px;
        }
        @include media($w1024) {
          padding: 45px 0 60px;
        }
        @include media($w768) {
          padding: 45px 0 50px;
        }
        ul {
          flex-wrap: wrap;
          justify-content: center;
          display: flex;
          li {
            @include transition;
            font-family: 'Montserrat', sans-serif !important;
          	font-size: 12px;
          	font-weight: 300;
          	line-height: 20px;
            text-transform: uppercase;
            cursor: pointer;
            margin: 0 20px;
            position: relative;
            @include media($w1366) {
              margin: 0 16px;
            }
            @include media($w1024) {
              margin: 0 12px 10px;
            }
            &:before {
              @include transition;
              @include translateY;
              content: '';
              background-image: url(../images/icons/angle--next--red.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center left;
              width: 12px;
              height: 12px;
              opacity: 0;
              pointer-events: none;
              position: absolute;
              top: calc(50% + 1px);
              left: 0;
            }
            a {
              color: $gray;
              &:hover {
                color: $red;
              }
            }
            &.active {
              font-weight: 700;
              pointer-events: none;
              &:before {
                opacity: 1;
                left: -15px;
              }
            }
          }
        }
      }
    }
  }
}
