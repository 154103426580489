.home-page {
  .testimonials {
    margin: 120px 0;
    @include media($w1366) {
      margin: 100px 0;
    }
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    &__inner {
      background-color: $gray;
      color: $white;
      clip-path: polygon(80px 0,100% 0,100% calc(100% - 80px),calc(100% - 80px) 100%,0 100%,0 80px);
      padding: 105px 180px 100px;
      @include media($w1366) {
        clip-path: polygon(50px 0,100% 0,100% calc(100% - 50px),calc(100% - 50px) 100%,0 100%,0 50px);
        padding: 95px 100px 90px;
      }
      @include media($w1024) {
        clip-path: polygon(40px 0,100% 0,100% calc(100% - 40px),calc(100% - 40px) 100%,0 100%,0 40px);
        padding: 85px 80px 80px;
      }
      @include media($w768) {
        padding: 75px 50px 70px;
      }
      @include media($w560) {
        padding: 65px 30px 60px;
      }
    }
    &__carousel {
      margin: 0 0 30px;
      &-item {
        @include transition;
        opacity: 0 !important;
        h3 {
          font-size: 36px;
          line-height: 48px;
          @include media($w1366) {
        		font-size: 33px;
        		line-height: 41px;
        	}
        	@include media($w1024) {
        		font-size: 30px;
        		line-height: 36px;
        	}
          @include media($w768) {
        		font-size: 27px;
        		line-height: 33px;
        	}
          @include media($w560) {
        		font-size: 24px;
        		line-height: 30px;
        	}
        }
        &.swiper-slide-active {
          opacity: 1 !important;
        }
      }
    }
    &__slider {
      padding: 0 0 0 65px;
      position: relative;
      &:before {
        @include translateY;
        content: '';
        background-image: url(../images/icons/quote-sign.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 45px;
        height: 45px;
        position: absolute;
        left: 0;
      }
      &-item {
        @include transition;
        opacity: 0 !important;
        h5 {
          margin: 0 0 4px;
        }
        &.swiper-slide-active {
          opacity: 1 !important;
        }
      }
      &-navigation {
        @include translateY;
        display: flex;
        position: absolute;
        z-index: 100;
        right: 0;
        @include media($w768) {
          display: none;
        }
        &-prev,
        &-next {
          cursor: pointer;
        }
        &-prev {
          margin: 0 30px 0 0;
        }
      }
    }
  }
}
