@keyframes overlayAnimation {
  0% {
    width: 0%;
    left: 0;
  }
  33% {
    width: 66.5%;
  }
  66% {
    left: auto;
    right: 33.5%;
  }
  100% {
    width: 0%;
  }
}

@keyframes textBlockAnimation {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

.home-page {
  .why-us {
    margin: 120px 0;
    @include media($w1366) {
      margin: 100px 0;
    }
    @include media($w1024) {
      margin: 80px 0;
    }
    @include media($w768) {
      margin: 60px 0;
    }
    &__header {
      text-align: center;
      margin: 0 0 110px;
      @include media($w1366) {
        margin: 0 0 100px;
      }
      @include media($w1024) {
        margin: 0 0 80px;
      }
      @include media($w768) {
        margin: 0 0 60px;
      }
    }
    &__slider {
      &-item {
        opacity: 0 !important;
        justify-content: flex-end;
        align-items: center;
        display: flex;
        &-overlay {
          background-color: $red;
          width: 0%;
          height: 100%;
          position: absolute;
          z-index: 25;
          top: 0;
          right: 33.5%;
          @include media($w768) {
            height: 0;
          }
        }
        &-image {
          background-size: cover;
          background-position: center center;
          width: 66.5%;
          height: 100%;
          opacity: 0;
          transition: all 0s ease 0.65s !important;
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          @include media($w768) {
            width: 100%;
            transition: all 0.4s ease 0s !important;
          }
        }
        &-copy {
          flex: 0 0 calc(33.5% + 80px);
          margin: 130px 0;
          position: relative;
          z-index: 50;
          left: 0%;
          @include media($w1366) {
            margin: 110px 0;
          }
          @include media($w1024) {
            flex: 0 0 45%;
            margin: 80px 0;
          }
          @include media($w768) {
            flex: 0 0 calc(100% - 50px);
            margin: 50px 0 95px;
          }
          &:before {
            content: '';
            background-color: $white;
            width: 82%;
            height: 80px;
            box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.1);
            position: absolute;
            z-index: -25;
            bottom: 0;
            left: 9%;
            @include media($w768) {
              display: none;
            }
          }
          &-bg {
            background-color: $white;
            height: 490px;
            padding: 50px 80px;
            align-items: center;
            display: flex;
            clip-path: polygon(50px 0,100% 0,100% 100%,0 100%,0 50px);
            position: relative;
            z-index: 50;
            left: 0%;
            @include media($w1366) {
              height: 440px;
              padding: 40px 50px;
              clip-path: polygon(40px 0,100% 0,100% 100%,0 100%,0 40px);
            }
            @include media($w1024) {
              height: 380px;
              padding: 30px;
            }
            @include media($w768) {
              height: 350px;
            }
          }
          &-inner {
            flex: 0 0 100%;
            h4 {
              margin: 0 0 50px;
              @include media($w1366) {
                margin: 0 0 40px;
              }
              @include media($w1024) {
                margin: 0 0 30px;
              }
            }
            p {
              @include media($w768) {
                font-size: 12px;
                line-height: 22px;
              }
            }
          }
        }
        &.swiper-slide-active {
          opacity: 1 !important;
          .why-us {
            &__slider {
              &-item {
                &-overlay {
                  animation-name: overlayAnimation;
                  animation-duration: 2s;
                  animation-timing-function: ease-in-out;
                }
                &-image {
                  opacity: 1;
                }
                &-copy {
                  animation-name: textBlockAnimation;
                  animation-duration: 1s;
                  animation-timing-function: ease-in-out;
                }
              }
            }
          }
        }
      }
      &-navigation {
        display: flex;
        position: absolute;
        z-index: 75;
        bottom: 75px;
        right: 0;
        @include media($w1366) {
          bottom: 60px;
        }
        @include media($w1024) {
          bottom: 35px;
        }
        @include media($w768) {
          display: none;
        }
        &-prev,
        &-next {
          cursor: pointer;
          img {
            width: 28px;
            @include media($w1024) {
              width: 24px;
            }
          }
        }
        &-prev {
          margin: 0 30px 0 0;
        }
      }
      &-dots {
        width: 100%;
        justify-content: center;
        display: none;
        position: absolute;
        z-index: 100;
        bottom: 35px;
        left: 0;
        @include media($w768) {
          display: flex;
        }
        .swiper-pagination-bullet {
          @include transition;
          border: 2px solid rgba($white,0.5);
          background-color: rgba($white,0.3);
          width: 25px;
          height: 25px;
          opacity: 1;
          margin: 0 7px;
          position: relative;
          &:before {
            @include transition;
            border-radius: 50%;
            content: '';
            background-color: $white;
            width: 9px;
            height: 9px;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 6px;
            left: 6px;
          }
          &.swiper-pagination-bullet-active {
            border-color: $white;
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
