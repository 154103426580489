.concept {
  background-color: $black;
  height: 545px;
  position: relative;
  @include media($w1366) {
    height: 500px;
  }
  @include media($w1024) {
    height: 425px;
  }
  @include media($w768) {
    height: 350px;
  }
  @include media($w560) {
    height: 300px;
  }
  &__image {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    position: absolute;
    z-index: 25;
    top: 0;
    left: 0;
  }
  &__inner {
    @include translateY;
    text-align: center;
    z-index: 50;
    h1 {
      flex: 0 0 100%;
      color: $white;
      margin: 0 auto 10px;
      text-shadow: 0px 3px 6px rgba($black,0.16);
      @include media($w560) {
        margin: 0;
      }
    }
  }
  &--post {
    .concept {
      &__inner {
        h1 {
          max-width: 1000px;
        }
      }
    }
  }
}
